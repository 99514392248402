<template>
	<div>
		<div class="info mb10">
			<span>院系：</span>
			<el-select v-model="depid" placeholder="请选择" clearable @change="changeSystem">
				<el-option v-for="item in systemList" :key="item.id" :label="item.name" :value="item.id" />
			</el-select>
			<span>班级：</span>
			<el-select v-model="classid" placeholder="请选择" clearable no-data-text="请先选择院系">
				<el-option v-for="item in classList" :key="item.id" :label="item.name" :value="item.id" />
			</el-select>
			<span>关键字：</span>
			<el-input v-model="keyWord" placeholder="请输入 姓名学号 关键字" />
		</div>
		<div class="info">
			<span>时间范围：</span>
			<el-date-picker v-model="searchDate" type="daterange" range-separator="~" start-placeholder="开始日期"
				end-placeholder="结束日期">
			</el-date-picker>
			<div>
				<el-button type="primary" @click="getData">查询</el-button>
				<el-button type="primary" @click="toAddAssessment" plain class="ml10">新增</el-button>
				<el-upload class="upload-data ml10 mr0" v-if="addPower"
					:action="imgBaseUrl + '/api/sysassessment/importExcelSuccess?recordType=student&userID=' + userID + '&token=' + token"
					ref="upload" accept=".xlsx,.xls" :on-success="onSuccess">
					<el-button type="primary" plain>导入数据</el-button>
				</el-upload>
				<el-button type="primary" class="" v-if="exportPower" @click="exportBtn" plain>导出数据</el-button>
				<el-button type="danger" class="ml10" v-if="editPower" @click="batchDelete" :disabled="!selectTotal"
					plain>批量删除</el-button>
			</div>
		</div>
		<!-- 学生 考核列表 -->
		<el-table class="mgt16" ref="multipleTable" :data="studentList" tooltip-effect="dark" style="width:100%"
			element-loading-text="Loading" border fit highlight-current-row stripe
			@selection-change="handleSelectionChange">
			<el-table-column type="selection" align="center" prop="id" label="序号"></el-table-column>
			<el-table-column align="left" prop="" label="院系名称">
				<template slot-scope="scope">
					<span v-if="scope.row.dname == null" class="tc_red">-</span>
					<span v-else>{{ scope.row.dname }}</span>
				</template>
			</el-table-column>
			<el-table-column align="left" prop="" label="班级名称">
				<template slot-scope="scope">
					<span v-if="scope.row.cname == null" class="tc_red">-</span>
					<span v-else>{{ scope.row.cname }}</span>
				</template>
			</el-table-column>

			<el-table-column align="left" prop="" label="班级编号">
				<template slot-scope="scope">
					<span v-if="scope.row.classnum == null" class="tc_red">-</span>
					<span v-else>{{ scope.row.classnum }}</span>
				</template>
			</el-table-column>

			<el-table-column align="left" prop="studentname" label="姓名学号">
				<template slot-scope="scope">
					<span v-if="scope.row.studentname == null" class="tc_red">-</span>
					<span v-else>{{ scope.row.studentname }}</span>
					<br />
					<span v-if="scope.row.studentnum == null" class="tc_red">-</span>
					<span v-else>{{ scope.row.studentnum }}</span>
				</template>
			</el-table-column>
			<el-table-column align="left" prop="category" label="评分类别"></el-table-column>
			<el-table-column align="left" prop="content" label="评分明细"></el-table-column>
			<el-table-column align="left" prop="score" label="课时评分">
				<template slot-scope="scope">
					<span v-if="scope.row.project == null" class="tc_red">-</span>
					<span v-else>{{ scope.row.project }}</span>
					<br />
					<span v-if="scope.row.score > 0" class="tc_blue">{{ scope.row.score }}</span>
					<span v-else-if="scope.row.score < 0" class="tc_red">{{ scope.row.score }}</span>
					<span v-else>{{ scope.row.score }}</span> 分
				</template>
			</el-table-column>
			<el-table-column align="left" prop="explains" label="补充说明"></el-table-column>
			<el-table-column align="left" prop="photo" label="附件佐证">
				<template slot-scope="scope">
					<span v-if="scope.row.photo == '[]'" class="tc_gray">暂无</span>
					<span v-else-if="scope.row.photo == null || scope.row.photo == ''" class="tc_gray">暂无</span>
					<table v-else>
						<tr style="background-color:transparent;">
							<td v-for="photoItem in scope.row.photoList" :key="photoItem" style="text-align:center;">
								<a :href="photoItem" target="_blank" data-lightbox="example-1">
									<img class='photoImg pointer' :src="photoItem" />
								</a>
							</td>
						</tr>
					</table>
				</template>
			</el-table-column>
			<el-table-column align="left" prop="createtime" label="评分人员">
				<template slot-scope="scope">
					<span v-if="scope.row.operatorname == null" class="tc_red">-</span>
					<span v-else>{{ scope.row.operatorname }}</span>
				</template>
			</el-table-column>
			<el-table-column align="left" prop="operatornum" label="工号">
				<template slot-scope="scope">
					<span v-if="scope.row.operatornum == null" class="tc_red">-</span>
					<span v-else>{{ scope.row.operatornum }}</span>
				</template>
			</el-table-column>
			<el-table-column align="left" prop="createtime" label="创建时间">
				<template slot-scope="scope">
					{{ scope.row.createtime }}
				</template>
			</el-table-column>

			<!-- <el-table-column align="left" prop="scoretime" label="评分时间">
				<template slot-scope="scope">
					{{ scope.row.scoretime }}
				</template>
			</el-table-column> -->
			<el-table-column property="address" label="操作" align="center" width="80px">
				<template slot-scope="scope">
					<div style="display:flex; justify-content:center; margin-bottom:5px;">
						<el-button type="primary" size="small" v-if="editPower == false" @click="handleView(scope.row)">
							查看</el-button>
						<el-button type="primary" size="small" v-if="editPower" @click="handleEdit(scope.row)">编辑
						</el-button>
					</div>
					<div style="display:flex; justify-content:center">
						<el-button type="danger" size="small" v-if="editPower" @click="handleBack(scope.row)">撤回
						</el-button>
					</div>
				</template>
			</el-table-column>
		</el-table>

		<!-- 分页 -->
		<el-pagination :page-sizes="[5, 10, 15, 20]" :page-size="pageSize" layout="total, sizes, prev, pager, next"
			:total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />

		<!-- 详情 -->
		<el-dialog title="详情" v-if="dialogFormVisible_view" :visible.sync="dialogFormVisible_view"
			:before-close="handleClose" width="50%" minWidth="600px">
			<TableDetail :detailData="detailData" />
		</el-dialog>

		<!-- 新增|编辑 -->
		<el-dialog v-if="dialogFormVisible_add" :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible_add"
			:before-close="handleClose" width="800px" minWidth="800px">
			<AddAssessment ref="dialogForm" @changeDialogFormVisible="changeDialogFormVisible_add($event)"
				:dialog-status="dialogStatus" :dialogStatus="dialogStatus" />
		</el-dialog>
		<el-dialog v-if="dialogFormVisible_edit" :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible_edit"
			:before-close="handleClose" width="800px" minWidth="800px">
			<EditAssessment ref="dialogForm" @changeDialogFormVisible="changeDialogFormVisible_edit($event)"
				:dialog-status="dialogStatus" :dialogStatus="dialogStatus" />
		</el-dialog>
	</div>
</template>

<script>
import * as api from "@/api/users";
import {
	getHttpUrl
} from "@/utils/request";
import {
	getToken
} from '@/utils/token.js'
import TableDetail from "@/views/components/TableDetail";
import AddAssessment from "@/views/add/addAssessment.vue";
import EditAssessment from "@/views/edit/editAssessment.vue";
export default {
	name: "StudentRecord",
	components: {
		TableDetail,
		AddAssessment,
		EditAssessment,
	},
	data() {
		return {
			imgBaseUrl: getHttpUrl(),
			studentList: [], //列表数据
			total: 0, // 列表长度
			page: 1, // 当前页
			pageSize: 10, // 当前页容量
			starts: 0, // 学生0，班级1
			depid: null, // 院系id
			classid: null, // 班级id
			scsId: null, //
			studentname: "", //姓名
			studentnum: "", //学号
			keyWord: "", // 合二为一，关键字

			searchDate: [], //时间段
			systemList: [], //院系列表
			classList: [], //班级列表
			listcategory: [], //评分类别
			dialogFormVisible_add: false,
			dialogFormVisible_view: false, // 详情窗口显示
			dialogFormVisible_edit: false, // 详情窗口编辑
			dialogStatus: "",
			textMap: {
				update: "编辑",
				create: "新增",
				view: "详情",
			},
			detailData: [], //详情数据
			selectData: [], //列表选中

			userID: "", // 当前用户ID，导入的参数
			token: "", // 交互凭证，导入的参数

			addPower: false, // 新增 权限
			editPower: false, // 编辑 权限
			exportPower: false, // 导入导出 权限
		};
	},
	// 也是 一种监听，跟 watch 有相似性
	computed: {
		selectTotal() {
			return this.selectData.length;
		},
	},
	// vue 在模板渲染成html前调用，即通常初始化某些属性值，然后再渲染成视图。
	// created() {
	//   this.load();
	// },
	// vue 在模板渲染成html后调用，通常是初始化页面完成后，再对html的dom节点进行一些需要的操作。
	mounted() {
		this.userID = getToken();
		this.token = getToken();
		// 读取角色 
		api.getCurrUserItem().then((res1) => {
			// 有没有编辑权限
			console.log("roleID = " + res1.data.roleid);
			// 是否管理员的权限
			this.addPower = api.checkRoleAddPowerAdmin(res1.data.roleid);
			this.editPower = api.checkRoleEditPowerAdmin(res1.data.roleid);
			this.exportPower = api.checkRoleExportPowerAdmin(res1.data.roleid);
		});
		this.getSystemList();
		this.getData();
	},
	methods: {
		// 获取院系列表
		getSystemList() {
			let {
				page,
				pageSize
			} = this;
			let params = {
				page,
				pageSize,
			};
			// 院系接口
			api.getSystemList(params).then((res) => (this.systemList = res.data.records));
		},
		// 获取评分类别
		getScoreCategory() {
			let params = {
				starts: this.starts, // 0学生 1班级
			};
			// 考核记录管理 评分类别 列表
			api.getScoreCategoryList(params).then((res) => {
				this.listcategory = res.data.records;
			});
		},
		// 关闭 模式对话框
		changeDialogFormVisible_add(data) {
			this.dialogFormVisible_add = data;
			// 刷新 界面数据
			this.getData();
		},
		// 关闭 模式对话框
		changeDialogFormVisible_edit(data) {
			this.dialogFormVisible_view = data;
			this.dialogFormVisible_edit = data;
			// 刷新 界面数据
			this.getData();
		},
		// 获取数据列表
		getData() {
			let {
				page,
				pageSize,
				depid,
				classid,
				studentname,
				studentnum,
				keyWord,
				starts,
				searchDate,
			} = this;
			let params = {
				page,
				pageSize,
				depid,
				classid,
				studentname,
				studentnum,
				keyWord,
				startTime: searchDate.length ? this.$moment(searchDate[0]).format("YYYY-MM-DD") : "",
				endTime: searchDate.length ? this.$moment(searchDate[1]).format("YYYY-MM-DD") : "",
				starts,
			};
			api.getAssessmentRecordList(params).then((res) => {
				if (res.status == 1) {
					this.total = res.data.total;
					this.studentList = res.data.rows;
					// 拆分成 图片列表
					if (this.studentList != null && this.studentList.length > 0) {
						this.studentList.forEach(element => {
							if (element.photo == null || element.photo == '' || element.photo ==
								'[]') {
								element.photoList = new Array();
							} else {
								element.photoList = new Array();
								let photoSrcList = element.photo.replace('[', '').replace(']', '')
									.split(',');
								photoSrcList.forEach(photoSrcTemp => {
									var temp = this.imgBaseUrl + photoSrcTemp;
									element.photoList.push(temp);
								});
							}
						});
					}
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		// 切换院系
		changeSystem(id) {
			api.getCardArgumentDetail({
				depid: id
			}).then((res) => {
				let classList = res.data.records.filter((item) => item.depid === id);
				this.classList = classList;
				this.classid = null;
				if (classList != null && classList.length > 0) {
					this.classid = classList[0].id;
				}
			});
		},
		// 上传成功勾子
		onSuccess() {
			this.$message({
				message: "导入成功！",
				type: "success",
			});
			this.getData();
		},
		onload() {
			// 读取角色 
			api.getCurrUserItem().then((res1) => {
				// 是否管理员的权限
				this.addPower = api.checkRoleAddPowerAdmin(res1.data.roleid);
				this.editPower = api.checkRoleEditPowerAdmin(res1.data.roleid);
				this.exportPower = api.checkRoleExportPowerAdmin(res1.data.roleid);
			});
		},
		// 导出
		exportBtn() {
			let {
				page,
				pageSize,
				depid,
				classid,
				studentname,
				studentnum,
				keyWord,
				starts,
				searchDate,
			} = this;
			let params = {
				page,
				pageSize,
				depid,
				classid,
				studentname,
				studentnum,
				keyWord,
				startTime: searchDate.length ? this.$moment(searchDate[0]).format("YYYY-MM-DD") : "",
				endTime: searchDate.length ? this.$moment(searchDate[1]).format("YYYY-MM-DD") : "",
				starts,
			};
			api.exportAssessmentRecordList(params, "Student").then((res) => {
				if (res.status == 1) {
					window.open(res.data, '_blank');
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		// 批量删除
		batchDelete() {
			let thatTemp = this;
			// 读取角色 
			api.getCurrUserItem().then(() => {
				if (thatTemp.editPower) {
					thatTemp.$confirm("确定要批量删除数据吗？该方法无法撤销？").then(() => {
						// 数组=>字符串
						api.batchDeleteAssessment({
							ids: thatTemp.selectedIDS
						}).then((res) => {
							if (res.status == 1) {
								thatTemp.getData();
								thatTemp.$message.success("删除成功");
							} else {
								thatTemp.$message.success("删除失败");
							}
						})
							.catch(() => {
								thatTemp.$message.success("出错了");
							});
					});
				} else {
					// success error info warning
					thatTemp.$message.warning("无权操作");
				}
			});
		},
		// 勾选变化勾子
		handleSelectionChange(val) {
			this.selectData = val;
			// 所选的 IDS
			let selectedIDS = "";
			for (let i = 0; i < this.selectData.length; i++) {
				selectedIDS += ',' + this.selectData[i].id;
			}
			this.selectedIDS = selectedIDS;
		},
		// 页容量切换
		handleSizeChange(val) {
			this.pageSize = val;
			this.getData();
		},
		// 当前页切换
		handleCurrentChange(val) {
			this.page = val;
			this.getData();
		},
		// 打开 新增 
		toAddAssessment() {
			let thatTemp = this;
			// 读取角色 
			api.getCurrUserItem().then(() => {
				// 编辑
				if (this.editPower) {
					thatTemp.dialogStatus = "create";
					thatTemp.dialogFormVisible_add = true;
					// nextTick里面的代码会在 DOM 更新 后执行，也会在 load 之后执行
					this.$nextTick(() => {
						// 加载数据
						this.$refs.dialogForm.showAddData(thatTemp.starts);
					});
				} else {
					thatTemp.$message.warning("无权操作");
				}
			});
		},
		// 点击了 查看 按钮
		handleView(row) {
			let thatTemp = this;
			// 读取角色 
			api.getCurrUserItem().then((res1) => {
				if (res1.status == 1) {
					if (api.chechRoleEditPower(res1.data.roleid)) {
						this.dialogFormVisible_view = true;
						this.detailData = [{
							leftLabel: "序号",
							leftValue: row.id,
							rightLabel: "姓名",
							rightValue: row.studentname,
						},
						{
							leftLabel: "学号",
							leftValue: row.studentid,
							rightLabel: "评分类别",
							rightValue: row.category,
						},
						{
							leftLabel: "评分明细",
							leftValue: row.content,
							rightLabel: "评分",
							rightValue: row.score,
						},
						{
							leftLabel: "课节时间",
							leftValue: row.project,
							rightLabel: "评分时间",
							rightValue: row.scoretime,
						},
						];
					} else {
						thatTemp.$message.warning("无权操作");
					}
				}
			});
		},
		// 点击了 编辑 按钮
		handleEdit(row) {
			let thatTemp = this;
			// 读取角色 
			api.getCurrUserItem().then(() => {
				// 编辑
				if (this.editPower) {
					thatTemp.dialogStatus = "update";
					thatTemp.dialogFormVisible_edit = true;
					thatTemp.form = row;
					console.log(thatTemp.form);
					// nextTick里面的代码会在 DOM 更新 后执行，也会在 load 之后执行
					this.$nextTick(() => {
						// 加载数据
						this.$refs.dialogForm.showData(row, thatTemp.starts);
					});
				} else {
					thatTemp.$message.warning("无权操作");
				}
			});
		},
		// 点击了 单个 撤回 按钮
		handleBack(row) {
			let thatTemp = this;
			// 读取角色 
			api.getCurrUserItem().then(() => {
				if (this.editPower) {
					// 弹出确认对话框
					thatTemp.$confirm("确认撤回？").then(() => {
						// 撤回操作
						api.backAssessment(row.id).then((res2) => {
							if (res2.status == 1) {
								thatTemp.getData();
								thatTemp.$message.success(res2.msg);
							} else {
								thatTemp.$message.error(res2.msg);
							}
						});
					});
				} else {
					// success error info warning
					thatTemp.$message.warning("无权操作");
				}
			});
		},
		// 点击了 单个 删除 按钮
		handleDelete(row) {
			let thatTemp = this;
			// 读取角色 
			api.getCurrUserItem().then(() => {
				if (this.editPower) {
					// 弹出确认对话框
					thatTemp.$confirm("确认删除？").then(() => {
						// 删除操作
						api.deleteAssessment(row.id).then((res2) => {
							if (res2.status == 1) {
								thatTemp.getData();
								thatTemp.$message.success(res2.msg);
							} else {
								thatTemp.$message.error(res2.msg);
							}
						});
					});
				} else {
					// success error info warning
					thatTemp.$message.warning("无权操作");
				}
			});
		},
		// 关闭
		handleClose(done) {
			done();
		},
	},
};
</script>
<style lang="scss" scoped>
.upload-data {
	float: left;
}

.photoImg {
	width: 20px;
	height: 20px;
	margin-right: 5px;
}
</style>
